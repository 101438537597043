import { QueryClient } from '@tanstack/react-query';
import { redirect } from 'react-router-dom';
import { RouteValues, createRoutes } from '~/routes';
import { authService } from '~/services/auth/auth-service';
import { userQuery } from '~/shared/api/user.queries';
import { hasAcceptedTerms } from '~/shared/hooks/useUserHasAcceptedTerms';
import { RoleModel } from '~/shared/models/role/RoleModel';
import { UserModel } from '~/shared/models/user/UserModel';

function urlStringNoOrigin(url: URL) {
  return url.toString().replace(url.origin, '');
}

function createRedirectUrl(requestUrlString: string) {
  const requestUrl = new URL(requestUrlString);
  requestUrl.hash = window.location.hash;
  return urlStringNoOrigin(requestUrl);
}

function createUrl(redirectUrl: string, route: RouteValues) {
  const loginUrl = new URL(route, 'http://localhost');
  loginUrl.searchParams.set('redirect_url', redirectUrl);
  return urlStringNoOrigin(loginUrl);
}

export async function checkAuth(request: Request) {
  await authService.initialized;

  if (!authService.isSignedIn) {
    const redirectUrl = createRedirectUrl(request.url);
    const loginUrl = createUrl(redirectUrl, createRoutes.SignIn);

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw redirect(loginUrl);
  }

  return null;
}

export async function checkUserAuthAndTerms(
  queryClient: QueryClient,
  request: Request,
): Promise<UserModel> {
  await authService.initialized;

  const user = await queryClient.ensureQueryData(userQuery());

  const hasAccepted = hasAcceptedTerms(user);

  if (!hasAccepted) {
    const redirectUrl = createRedirectUrl(request.url);
    const termsUrl = createUrl(redirectUrl, createRoutes.TermsConditions);

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw redirect(termsUrl);
  }

  return user;
}

export async function getAuthUser(queryClient: QueryClient, request: Request) {
  await authService.initialized;

  if (!authService.isSignedIn) {
    const redirectUrl = createRedirectUrl(request.url);
    const loginUrl = createUrl(redirectUrl, createRoutes.SignIn);

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw redirect(loginUrl);
  }

  return await queryClient.ensureQueryData(userQuery());
}

export async function doesUserHaveRequiredRole(
  queryClient: QueryClient,
  requiredRole: RoleModel,
) {
  const user = await queryClient.ensureQueryData(userQuery());

  return user.roles.some((role) => requiredRole.isEqual(role));
}

export async function checkAuthAndRole(
  queryClient: QueryClient,
  request: Request,
  requiredRole: RoleModel,
) {
  await checkUserAuthAndTerms(queryClient, request);

  const hasRequiredRole = await doesUserHaveRequiredRole(
    queryClient,
    requiredRole,
  );

  if (!hasRequiredRole) {
    const redirectUrl = createRedirectUrl(request.url);
    const redirectRoute = createUrl(redirectUrl, createRoutes.Devices);

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw redirect(redirectRoute);
  }

  return null;
}
